import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { ITemplateSection, ITemplateSectionText, SideBarType } from 'src/app/shared/shared.definitions';
import { FormGroup, FormControl } from '@angular/forms';
import { TinymceComponent } from 'ngx-tinymce';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit {

  @ViewChild('tinymce') private tinymce: TinymceComponent;

  @Input() sidenav: MatSidenav;

  selectedSection: ITemplateSection;
  content: ITemplateSectionText;
  textForm: FormGroup;
  tinymceConfig: any;
  isModified: boolean;

  constructor(
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.sharedService.selectedSection.subscribe(section => {
      this.selectedSection = section;
      this.content = <ITemplateSectionText>(this.selectedSection.content || {});
      this.textForm = new FormGroup({
        html: new FormControl(this.content.html || ''),
        options: new FormGroup({
          verticalAlign: new FormControl(this.content.options && this.content.options.verticalAlign || 'top'),
          horizontalAlign: new FormControl(this.content.options && this.content.options.horizontalAlign || 'left'),
          paddingTop: new FormControl(this.content.options && this.content.options.paddingTop || '5px'),
          paddingBottom: new FormControl(this.content.options && this.content.options.paddingBottom || '5px'),
          paddingLeft: new FormControl(this.content.options && this.content.options.paddingLeft || '5px'),
          paddingRight: new FormControl(this.content.options && this.content.options.paddingRight || '5px'),
          marginTop: new FormControl(this.content.options && this.content.options.marginTop || '5px'),
          marginBottom: new FormControl(this.content.options && this.content.options.marginBottom || '5px'),
          marginLeft: new FormControl(this.content.options && this.content.options.marginLeft || '5px'),
          marginRight: new FormControl(this.content.options && this.content.options.marginRight || '5px'),
          backgroundColor: new FormControl(this.content.options && this.content.options.backgroundColor || '#ffffff'),
        })
      });

      this.setEditorBackgroundColor(this.textForm.value.options.backgroundColor);
      this.textForm.valueChanges.subscribe(() => this.isModified = true);
      this.textForm.controls.options.get('backgroundColor').valueChanges.subscribe((value) => {
        this.setEditorBackgroundColor(value);
      });
    });

    this.tinymceConfig = {
      plugins: 'textcolor colorpicker image link table code paste',
      toolbar1:  `undo redo | styleselect | fontselect fontsizeselect | bold italic |
        alignleft aligncenter alignright alignjustify | indent outdent | forecolor backcolor`,
      toolbar2: `link image table | code`,
      height: 350,
      inline_styles: true,
      entity_encoding: 'raw',
      paste_auto_cleanup_on_paste: true,
      fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
      paste_postprocess: (pl, o) => {
          o.node.innerHTML = o.node.innerHTML.replace(/&nbsp;/ig, ' ');
      },
    };

    this.tinymce.ready.subscribe(() => {
      this.setEditorBackgroundColor(this.textForm.value.options.backgroundColor);
    });
  }

  onSave() {
    Object.keys(this.textForm.value).forEach(key => this.content[key] = this.textForm.value[key]);
    this.selectedSection.content = this.content;
    this.isModified = false;
    this.sharedService.editSection(this.selectedSection);
    this.sidenav.close();
  }

  onDelete() {
    this.sharedService.deleteSection(this.selectedSection);
    this.sidenav.close();
  }

  onBack() {
    let confirmation = true;
    if (this.isModified) {
      confirmation = confirm('Nem mentett változásaid vannak! Biztos visszalépsz?');
    }
    if (confirmation) {
      this.isModified = false;
      this.sidenav.close();
    }
  }

  private setEditorBackgroundColor(color: string) {
    if (this.tinymce.instance) {
      this.tinymce.instance.contentAreaContainer.style.backgroundColor = color;
    }
  }

}
