import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { ITemplate, ITemplateDetails, SideBarType } from 'src/app/shared/shared.definitions';
import { SharedService } from 'src/app/shared/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-template-settings',
  templateUrl: './template-settings.component.html',
  styleUrls: ['./template-settings.component.scss']
})
export class TemplateSettingsComponent implements OnInit {

  @Input() sidenav: MatSidenav;

  templateSettingsForm: FormGroup;
  template: ITemplate;
  matcher: ErrorStateMatcher;

  constructor(
    private sharedService: SharedService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.sharedService.template.subscribe(template => {
      this.template = template;
      this.templateSettingsForm = new FormGroup({
        preHeader: new FormControl(this.template.details && this.template.details.preHeader || ''),
        unsubscribe: new FormControl(this.template.details && this.template.details.unsubscribe || ''),
        paddingTop: new FormControl(this.template.details && this.template.details.paddingTop || '10px'),
        paddingBottom: new FormControl(this.template.details && this.template.details.paddingBottom || '10px'),
        paddingLeft: new FormControl(this.template.details && this.template.details.paddingLeft || '10px'),
        paddingRight: new FormControl(this.template.details && this.template.details.paddingRight || '10px'),
        marginTop: new FormControl(this.template.details && this.template.details.marginTop || '0px'),
        marginBottom: new FormControl(this.template.details && this.template.details.marginBottom || '0px'),
        backgroundColor: new FormControl(this.template.details && this.template.details.backgroundColor || '#F2F2F2'),
        foregroundColor: new FormControl(this.template.details && this.template.details.foregroundColor || '#fff'),
        maxWidth: new FormControl(this.template.details && this.template.details.maxWidth || '600px'),
        templateHash: new FormControl('', this.templateHashValidator)
      });
    });
  }

  onDownloadTemplate() {
    const templateString = btoa(unescape(encodeURIComponent(JSON.stringify(this.template))));
    this.downloadFile(this.template.details.name, templateString);
  }

  onPasteTemplate() {
    const { templateHash } = this.templateSettingsForm.value;
    if (templateHash) {
      const template = JSON.parse(decodeURIComponent(escape(atob(templateHash))));
      this.sharedService.updateTemplate(template, true);
    }
  }

  onSave() {
    const {
      preHeader, unsubscribe, paddingTop, paddingBottom, paddingLeft, paddingRight,
      marginTop, marginBottom, backgroundColor, maxWidth, foregroundColor
    } = this.templateSettingsForm.value;
    const details: ITemplateDetails = {
      preHeader, unsubscribe, paddingTop, paddingBottom, paddingLeft, paddingRight,
      marginTop, marginBottom, backgroundColor, maxWidth, foregroundColor
    };
    this.sharedService.setTemplateDetails(details);
    this.sidenav.close();
  }

  onBack() {
    this.sidenav.close();
  }

  private templateHashValidator(control: FormControl): ValidationErrors {
    let error = false;
    try {
      const template = JSON.parse(decodeURIComponent(escape(atob(control.value))));
    } catch (e) {
      error = true;
    }
    return error ? { invalidHash: true } : null;
  }

  private downloadFile(filename: string, text: string) {
    const _filename = (filename || 'template').normalize('NFD').toLowerCase().replace(/\s/gi, '_');
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + text);
    element.setAttribute('download', `${_filename}.txt`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

}
