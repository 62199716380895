import {
  Injectable, Type, ComponentRef, EmbeddedViewRef, ComponentFactoryResolver, ApplicationRef, Injector, ViewContainerRef
} from '@angular/core';
import { SideBarType, ITemplate, ITemplateSection, SectionType, ITemplateDetails, ITemplateRow, ISidebarType } from './shared.definitions';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../core/api.service';

const NEWSLETTER_CSS = `
  /* CLIENT-SPECIFIC STYLES */
  * { font-family: 'Verdana', Arial, Helvetica, sans-serif !important; }
  body, table, td, th, a { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
  table, td, th { mso-table-lspace: 0pt; mso-table-rspace: 0pt; padding: 0; }
  img { -ms-interpolation-mode: bicubic; }

  /* RESET STYLES */
  img { border: 0; outline: none; text-decoration: none; }
  table { border-collapse: collapse !important; }
  body { margin: 0 !important; padding: 0 !important; width: 100% !important; }

  /* iOS BLUE LINKS */
  a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
  }

  /* ANDROID CENTER FIX */
  div[style*="margin: 16px 0;"] { margin: 0 !important; }

  .section-placeheolder { display: none !important }

  /* OUTLOOK */
  #outlook a{
    padding:0;
  }
  .ReadMsgBody{
      width:100%;
  }
  * {
    -webkit-text-size-adjust:100%;
    -ms-text-size-adjust: 100%;
  }
  html,
  body {
    margin: 0 auto !important;
    padding: 0 !important;
    height: 100% !important;
    width: 100% !important;
  }
  .ExternalClass{
      width:100%;
  }
  .ExternalClass,.ExternalClass p,.ExternalClass span,.ExternalClass font,.ExternalClass td,.ExternalClass th,.ExternalClass div{
      line-height:100%;
  }
  a img{
      border:none;
  }
  img{
      display:block;
      -ms-interpolation-mode:bicubic;
  }
  table, td, th {
      mso-table-lspace: 0pt !important;
      mso-table-rspace: 0pt !important;
  }
  a {
    text-decoration: none;
  }

  a:visited {
    color: #d8b66c !important;
  }

  /* MEDIA QUERIES */
  @media all and (max-width:739px) {
    table { height: auto !important; width: 100% !important }
    .mobile { display: block !important; width: 100% !important; border-left: 0 !important; border-right: 0 !important; box-sizing: border-box !important; }
    .template-margin { display: none !important; }
    .wrapper{ width:100%!important; padding: 0 !important; }
    .container{ width:100%!important; margin: 0 !important; padding: 10px 10px 10px 10px !important }
    .img{ width:100% !important; height:auto !important; }
    .article-content { padding-left: 10px; padding-right: 10px; }
    .contact-content { padding-left: 10px }
    *[class="mobileOff"] { width: 0px !important; display: none !important; }
    *[class*="mobileOn"] { display: block !important; max-height:none !important; }
  }
`;

const INIT_TEMPLATE_DETAILS: ITemplateDetails = {
  name: '',
  preHeader: '',
  unsubscribe: '',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingLeft: '10px',
  paddingRight: '10px',
  marginTop: '0px',
  marginBottom: '0px',
  backgroundColor: '#F2F2F2',
  foregroundColor: '#fff',
  maxWidth: '600px',
};

const INIT_TEMPLATE: ITemplate = Object.freeze({
  id: '',
  details: INIT_TEMPLATE_DETAILS,
  rows: [],
  html: '',
  isOnline: false
});

const INIT_SIDEBARTYPE: ISidebarType = {
  sidebar: SideBarType.NONE,
  mode: ''
};

@Injectable()
export class SharedService {

  private sideBarTypeSubject: BehaviorSubject<ISidebarType> = new BehaviorSubject<ISidebarType>(INIT_SIDEBARTYPE);
  public sideBarType: Observable<ISidebarType> = this.sideBarTypeSubject.asObservable();

  private templateSubject: BehaviorSubject<ITemplate> = new BehaviorSubject<ITemplate>(this.getInitTemplate());
  public template: Observable<ITemplate> = this.templateSubject.asObservable();

  private selectedSectionSubject: BehaviorSubject<ITemplateSection> = new BehaviorSubject<ITemplateSection>(null);
  public selectedSection: Observable<ITemplateSection> = this.selectedSectionSubject.asObservable();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private apiService: ApiService
  ) { }

  public updateTemplate(template: ITemplate, emptyId = false) {
    if (emptyId) {
      template.id = '';
    }
    this.templateSubject.next(template);
    this.setSelectedTemplateLocal(template);
  }

  public setTemplateDetails(details: ITemplateDetails) {
    const template = this.templateSubject.value;
    const templateDetails = Object.assign(template.details, details);
    template.details = templateDetails;
    this.updateTemplate(template);
  }

  public setSideBarType(sidebar: SideBarType, mode: string = 'over') {
    this.sideBarTypeSubject.next({ sidebar, mode });
  }

  public selectSection(value: ITemplateSection) {
    this.selectedSectionSubject.next(value);
  }

  public editSection(value: ITemplateSection) {
    const template = this.templateSubject.value;
    const section = this.findSectionById(value.id);
    Object.assign(section, value);
    this.updateTemplate(template);
  }

  public editRow(value: ITemplateRow) {
    const template = this.templateSubject.value;
    const row = this.findRowById(value.id);
    Object.assign(row, value);
    this.updateTemplate(template);
  }

  public deleteSection(value: ITemplateSection) {
    const template = this.templateSubject.value;
    const { rowIndex, colIndex } = template.rows
      .map((_row, _rowIndex) => {
      const _colIndex = _row.sections.indexOf(_row.sections.find(section => section.id === value.id));
      if (_colIndex !== -1) {
        return {
          rowIndex: _rowIndex,
          colIndex: _colIndex
        };
      }
      return null;
    })
      .find(x => x !== null);
    template.rows[rowIndex].sections[colIndex].content = null;
    template.rows[rowIndex].sections[colIndex].icon = null;
    this.updateTemplate(template);
  }

  public initTemplate() {
    const template = Object.assign({}, INIT_TEMPLATE);
    template.rows = [];
    template.details = Object.assign({}, INIT_TEMPLATE_DETAILS);
    this.updateTemplate(template);
  }

  public saveTemplate(template: ITemplate): Observable<any> {
    console.log(template);
    let request;
    if (template.id) {
      if (template.isOnline) {
        request = this.apiService.updateTemplate(template);
      } else {
        request = this.apiService.createTemplate(template);
      }
    } else {
      template.id = this.generateID();
      request = this.apiService.createTemplate(template);
    }
    return request.pipe(
      map((val) => {
        this.updateTemplate(template);
        return val;
      }),
      catchError((err) => {
        const savedTemplates: ITemplate[] = localStorage.getItem('templates') ? JSON.parse(localStorage.getItem('templates')) : [];
        let savedTemplate = savedTemplates.find(_template => _template.id === template.id);
        template.isOnline = false;
        if (savedTemplate) {
          savedTemplate = Object.assign(savedTemplate, template);
        } else {
          savedTemplates.push(template);
        }
        this.updateTemplate(template);
        this.setTemplatesLocal(savedTemplates);
        return of(false);
      }));
  }

  public deleteTemplate(template: ITemplate): Observable<ITemplate[]> {
    if (template.isOnline) {
      return this.apiService.deleteTemplate(template);
    } else {
      const templates = this.deleteTemplateLocalById(template.id);
      return of(templates);
    }
  }

  public openEditor(type: SectionType) {
    switch (type) {
      case SectionType.TEXT:       this.setSideBarType(SideBarType.EDIT_TEXT); break;
      case SectionType.IMAGE:      this.setSideBarType(SideBarType.EDIT_IMAGE); break;
      case SectionType.SOCIAL:     this.setSideBarType(SideBarType.EDIT_SOCIAL); break;
      case SectionType.SEPARATOR:  this.setSideBarType(SideBarType.EDIT_SEPARATOR); break;
      case SectionType.ARTICLE:    this.setSideBarType(SideBarType.EDIT_ARTICLE); break;
      case SectionType.VIDEO:      this.setSideBarType(SideBarType.EDIT_VIDEO); break;
    }
  }

  public generateHtml(): string {
    const bodyContent: string = this.formatBody();
    const template = this.templateSubject.value;

    const head = `
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1>
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <!--<meta name=”x-apple-disable-message-reformatting”>-->
        <style>${NEWSLETTER_CSS}</style>
        <!--[if gte mso 7]>
          <xml>
            <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
          </xml>
          <style type="text/css">
            body, table, td { font-family: 'Verdana', Arial, Helvetica, sans-serif !important; }
          </style>
        <![endif]-->
      </head>
      `;
    const body = `
      <body width="100%" style="margin:0; padding:0; mso-line-height-rule: exactly; background-color: ${template.details.backgroundColor}">
        <span class="preheader" style="display: none !important;">${template.details.preHeader}</span>
        <table role="presentation" border="0" cellspacing="0" width="1280" style="width: 100%">
          <tbody>
            <tr>
              <th stlye="text-align:left;font-weight:normal;" class="template-margin" width="1" height="${template.details.marginTop}"></th>
            </tr>
            <tr>
              <th align="center" style="text-align:left;font-weight:normal;">
                <center>
                  <table role="presentation" width="${parseInt(template.details.maxWidth)}" style="max-width: 100%; width: ${template.details.maxWidth};">
                    <tr>
                      <th style="text-align:left;font-weight:normal;">
                        <table width="${parseInt(template.details.maxWidth)}" class="container" style="box-sizing: border-box !important; max-width: 100%; width: ${template.details.maxWidth};">
                            <tr>
                              <th style="text-align:left;font-weight:normal;" class="main-padding-top" width="1" height="${parseInt(template.details.paddingTop)}"></th>
                            </tr>
                            <tr>
                              <th style="text-align:left;font-weight:normal;" class="main-padding-left" width="${parseInt(template.details.paddingLeft)}"></th>
                              <th style="text-align:left;font-weight:normal;">
                                ${bodyContent}
                                <small class="unsubscribe" style="display: block !important;
                                  width: 100% !important; text-align: center !important; margin-top: 10px">
                                  ${template.details.unsubscribe}
                                </small>
                              </th>
                              <th style="text-align:left;font-weight:normal;" class="main-padding-right" width="${parseInt(template.details.paddingRight)}"></th>
                            </tr>
                            <tr>
                              <th style="text-align:left;font-weight:normal;" class="main-padding-bottom" width="1" height="${parseInt(template.details.paddingBottom)}"></th>
                            </tr>
                        </table>
                      </th>
                    </tr>
                    <tr>
                      <th style="padding-top:50px;text-align:left;font-weight:normal;" class="contact-content">
                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" width="${parseInt(template.details.maxWidth) - (parseInt(template.details.paddingLeft) + parseInt(template.details.paddingRight))}" style="width:100%;">
                          <tr>
                            <th style="font-size:20px;line-height:1.3;font-family:'EB Garamond',arial,tahoma;color:#544a3b;text-align:left;font-weight:bold;">
                              Kapcsolat
                            </th>
                          </tr>
                          <tr class="divider-row" style="line-height: 1px; -mso-line-height: exactly;">
                            <td height="22" style="font-size: 1px; line-height: 1px; -mso-line-height: exactly;">
                              <table role="presentation" cellspacing="0" cellpadding="0" border="0" height="22" style="width: 100%; font-size: 1px; line-height: 1px; -mso-line-height: exactly;">
                                <tr style="line-height: 1px; -mso-line-height: exactly;">
                                  <td class="divider-margin-top keep-width-on-mobile" width="40" height="10" style="width: 40px; font-size: 1px; line-height: 1px; -mso-line-height: exactly;"></td>
                                  <td class="divider-margin-top" height="10" style="font-size: 1px; line-height: 1px; -mso-line-height: exactly;"></td>
                                </tr>
                                <tr style="line-height: 1px; -mso-line-height: exactly;">
                                  <td class="divider keep-width-on-mobile" width="40" height="1" style="width: 40px; border-bottom: 2px solid #D8B66C; font-size: 1px; line-height: 1px; -mso-line-height: exactly;"></td>
                                  <td style="font-size:0px;line-height:0px;font-weight:normal;" height="1">&nbsp;</td>
                                </tr>
                                <tr style="line-height: 1px; -mso-line-height: exactly;">
                                  <td class="divider-margin-bottom keep-width-on-mobile" width="40" height="10" style="width: 40px; font-size: 1px; line-height: 1px; -mso-line-height: exactly;"></td>
                                  <td class="divider-margin-bottom" height="10" style="font-size: 1px; line-height: 1px; -mso-line-height: exactly;"></td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </th>
                    </tr>
                    <tr>
                      <th style="padding-top:50px;" class="contact-content">
                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" width="${parseInt(template.details.maxWidth) - (parseInt(template.details.paddingLeft) + parseInt(template.details.paddingRight))}" style="width:100%;">
                          <tr>
                            <th style="font-size:13px;line-height:1.3;font-family:'EB Garamond',arial,tahoma;color:#827e7c;text-align:left;font-weight:normal;" width="245" class="mobile">
                              Általános információk<br>
                              <a href="mailto:info@varkapitanysag.hu" style="color:#315d76;text-decoration:none;font-style:italic;font-weight:bold;">info@varkapitanysag.hu</a>
                            </th>
                            <th width="10" height="20" class="mobile" style="font-weight:normal;">&nbsp;</th>
                            <th style="font-size:13px;line-height:1.3;font-family:'EB Garamond',arial,tahoma;color:#827e7c;text-align:left;font-weight:normal;" width="245" class="mobile">
                              Sajtómegkeresések<br>
                              <a href="mailto:sajto@varkapitanysag.hu" style="color:#315d76;text-decoration:none;font-style:italic;font-weight:bold;">sajto@varkapitanysag.hu</a>
                            </th>
                            <th width="10" height="20" class="mobile" style="font-weight:normal;">&nbsp;</th>
                            <th width="150" class="mobile">
                              <table cellspacing="0" cellpadding="0" border="0" align="center" width="100%" style="width:100%;">
                                <tr>
                                  <th style="font-weight:normal;">&nbsp;</th>
                                  <th class="keep-width-on-mobile" style="font-size:0px;line-height:0px;padding:0px;" width="30">
                                    <a href="https://www.youtube.com/channel/UCagmftdlFnQcb93Y7wzhvXQ" target="_blank">
                                      <img src="http://newsletter.hauszmannhirlevel.hu/Content/73f8ee14e83545979c0cdf4eb83eb025.png" width="100%" height="auto" style="width:100%;height:auto;display:block;margin:0px auto;" alt="">
                                    </a>
                                  </th>
                                  <th style="font-weight:normal;">&nbsp;</th>
                                  <th class="keep-width-on-mobile" style="font-size:0px;line-height:0px;padding:0px;" width="30">
                                    <a href="https://www.instagram.com/nemzetihauszmannprogram/" target="_blank">
                                      <img src="http://newsletter.hauszmannhirlevel.hu/Content/5b491805eb0e4c9caec8cf88a894d374.png" width="100%" height="auto" style="width:100%;height:auto;display:block;margin:0px auto;" alt="">
                                    </a>
                                  </th>
                                  <th style="font-weight:normal;">&nbsp;</th>
                                  <th class="keep-width-on-mobile" style="font-size:0px;line-height:0px;padding:0px;" width="30">
                                    <a href="https://www.facebook.com/nemzetihauszmannprogram/" target="_blank">
                                      <img src="http://newsletter.hauszmannhirlevel.hu/Content/fbadc39eac534778abc0df154616021d.png" width="100%" height="auto" style="width:100%;height:auto;display:block;margin:0px auto;" alt="">
                                    </a>
                                  </th>
                                  <th style="font-weight:normal;">&nbsp;</th>
                                </tr>
                              </table>
                            </th>
                          </tr>
                        </table>
                      </th>
                    </tr>
                    <tr>
                      <th style="padding-top:50px;" class="contact-content">
                        <table cellspacing="0" cellpadding="0" border="0" align="center" width="${parseInt(template.details.maxWidth) - (parseInt(template.details.paddingLeft) + parseInt(template.details.paddingRight))}" style="width:100%;">
                          <tr>
                            <th style="font-size:13px;line-height:1;font-family:'EB Garamond',arial,tahoma;color:#827e7c;text-align:left;font-weight:normal;" class="mobile">
                              Minden jog fenntartva. &copy; <b>Nemzeti Hauszmann Program</b>
                            </th>
                            <th width="20" height="20" class="mobile" style="font-weight:normal;">&nbsp;</th>
                            <th style="font-size:13px;line-height:1;font-family:'EB Garamond',arial,tahoma;color:#315d76;text-align:left;font-weight:bold;" class="mobile">
                              <a href="https://hirlevel.nemzetihauszmannprogram.hu/impresszum" target="_blank" style="text-decoration:none;color:#315d76;">Impresszum</a>
                            </th>
                          </tr>
                        </table>
                      </th>
                    </tr>
                  </table>
                </center>
              </td>
            </tr>
            <tr>
              <td class="template-margin" width="1" height="${template.details.marginBottom}"></td>
            </tr>
          </tbody>
        </table>
      </body>
    `.replace(/<!--[\s\S]*?(?:-->|$)/g, '');

    const html =
      `<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
        ${this.formatHtml(`${head}${body}`)}
      </html>`;

    return html;
  }

  private formatBody(): string {
    const bodyContent: HTMLElement = (<any>document.cloneNode(true)).getElementById('section-layout-list');
    Array.from(bodyContent.getElementsByClassName('remove-from-mail')).forEach(item => item.parentNode.removeChild(item));
    return bodyContent.outerHTML;
  }

  private formatHtml(htmlString: string): string {
    const html = document.createElement('html');
    html.innerHTML = htmlString.trim();

    return this.indent(html, 1).innerHTML;
  }

  private indent(node: Element, level: number = 0) {
    const indentBefore = new Array(level++ + 1).join('  '),
    indentAfter  = new Array(level - 1).join('  ');
    let textNode;

    Array.from(node.children).forEach((element, index) => {
      textNode = document.createTextNode('\n' + indentBefore);
      node.insertBefore(textNode, element);
      this.indent(element, level);
      if (node.lastElementChild === element) {
        textNode = document.createTextNode('\n' + indentAfter);
        node.appendChild(textNode);
      }
    });

    return node;
  }

  public findSectionById(id: number): ITemplateSection {
    const template = this.templateSubject.value;
    return template.rows.filter(row => row.sections.find(section => section.id === id))[0].sections.find(section => section.id === id);
  }

  public findRowById(id: number): ITemplateRow {
    const template = this.templateSubject.value;
    return template.rows.find(row => row.id === id);
  }

  public createElement(component: Type<any>, parentElement?: Element): ComponentRef<any> {
    // 1. Create a component reference from the component
    const componentRef: ComponentRef<any> = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);

    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // 3. Get DOM element from component
    const domElement = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // 4. Append DOM element to the parent element
    if (parentElement) {
        parentElement.appendChild(domElement);
    }

    return componentRef;
  }

  public createElementVC(component: Type<any>, viewContainerRef: ViewContainerRef): ComponentRef<any> {
    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef: ComponentRef<any> = viewContainerRef.createComponent(factory);
    return componentRef;
  }

  private getInitTemplate() {
    const template = this.getSelectedTemplateLocal();
    return template || INIT_TEMPLATE;
  }

  private generateID() {
    return Math.random().toString(36).substr(2, 9);
  }

  public getTemplatesLocal(): ITemplate[] {
    return localStorage.getItem('templates') ? JSON.parse(localStorage.getItem('templates')) : [];
  }

  public setTemplatesLocal(templates: ITemplate[]) {
    localStorage.setItem('templates', JSON.stringify(templates));
  }

  public getTemplateLocalById(id: string): ITemplate {
    const savedTemplates: ITemplate[] = localStorage.getItem('templates') ? JSON.parse(localStorage.getItem('templates')) : [];
    return savedTemplates.find(_template => _template.id === id);
  }

  public deleteTemplateLocalById(id: string): ITemplate[] {
    const savedTemplates: ITemplate[] = localStorage.getItem('templates') ? JSON.parse(localStorage.getItem('templates')) : [];
    const savedTemplate = this.getTemplateLocalById(id);
    const index = savedTemplates.indexOf(savedTemplate);
    savedTemplates.splice(index, 1);
    this.setTemplatesLocal(savedTemplates);
    return savedTemplates;
  }

  public getSelectedTemplateLocal(): ITemplate {
    return <ITemplate>JSON.parse(localStorage.getItem('template'));
  }

  public setSelectedTemplateLocal(template: ITemplate) {
    localStorage.setItem('template', JSON.stringify(template));
  }

  public getOuterSectionWidth(section: ITemplateSection): number {
    const template = this.templateSubject.getValue();
    const mainPadding = parseInt(template.details.paddingLeft, 10) + parseInt(template.details.paddingRight, 10);
    const fullWidth = (parseInt(template.details.maxWidth, 10) - mainPadding) / (100 / section.layout.size);
    return fullWidth;
  }

  public getInnerSectionWidth(section: ITemplateSection, withPadding: boolean = false): number {
    if (section.content) {
      const template = this.templateSubject.getValue();
      const mainPadding = parseInt(template.details.paddingLeft, 10) + parseInt(template.details.paddingRight, 10);
      const fullWidth = (parseInt(template.details.maxWidth, 10) - mainPadding) / (100 / section.layout.size);
      const sectionMargin = parseInt(section.content.options.marginLeft, 10)  + parseInt(section.content.options.marginRight, 10);
      if (withPadding) {
        const sectionPadding = parseInt(section.content.options.paddingLeft, 10)  + parseInt(section.content.options.paddingRight, 10);
        return fullWidth - (sectionMargin + sectionPadding);
      }
      return fullWidth - sectionMargin;
    }
    return 0;
  }
}
